import React from "react"
import SEO from "../components/seo"
import Layout from "../components/en/ENlayout"
import AreaDePractica from "../components/areaDePractica"

export default function areasDePractica() {
  const areas = [
    {
      titulo: "Administration",
      descripcion:
        "In serving the transportation and trade industry, we represent all kinds of international players, like trading companies, underwriters, cargo interests, ship owners, marine terminal operators, indirect carriers, ferry owners, tug and tow operators, cruise lines, surveyors, trucking companies, vessel manufacturers and marine construction companies.",
    },
    {
      titulo: "Registry Law",
      descripcion:
        "In serving the transportation and trade industry, we represent all kinds of international players, like trading companies, underwriters, cargo interests, ship owners, marine terminal operators, indirect carriers, ferry owners, tug and tow operators, cruise lines, surveyors, trucking companies, vessel manufacturers and marine construction companies.",
    },
    {
      titulo: "Cows",
      descripcion:
        "In serving the transportation and trade industry, we represent all kinds of international players, like trading companies, underwriters, cargo interests, ship owners, marine terminal operators, indirect carriers, ferry owners, tug and tow operators, cruise lines, surveyors, trucking companies, vessel manufacturers and marine construction companies.",
    },
    {
      titulo: "Environmental Law",
      descripcion:
        "In serving the transportation and trade industry, we represent all kinds of international players, like trading companies, underwriters, cargo interests, ship owners, marine terminal operators, indirect carriers, ferry owners, tug and tow operators, cruise lines, surveyors, trucking companies, vessel manufacturers and marine construction companies.",
    },
  ]
  const divAreas = areas.map(area => <AreaDePractica area={area} />)
  return (
    <div>
      <Layout>
        <SEO title="Areas de practica" />
        <h1>Areas de practica</h1>
        <div>{divAreas}</div>
      </Layout>
    </div>
  )
}
